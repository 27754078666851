import { Flex, Text } from "@chakra-ui/react";

import { FC } from "react";

export const ChatActionBar: FC<{
  direction: "top" | "bottom";
  userId?: string;
  onPost(): void;
  onDirectionClick(): void;
}> = ({ direction, onPost, onDirectionClick }) => {
  return (
    <Flex w="full" alignItems="center" justifyContent="flex-end" gap="8px">
      <Text
        size="lg"
        variant="link"
        onClick={() => onPost()}
      >{`[post a reply]`}</Text>
      <Text
        size="lg"
        variant="link"
        onClick={() => onDirectionClick()}
      >{`[scroll to ${direction}]`}</Text>
    </Flex>
  );
};
