import { useSupabase } from "@/hooks/useSupabase";
import { ReferralAccount } from "@/types/supabase.entity";
import { Button, Flex, Input, Text } from "@chakra-ui/react";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";

export const HomePageLeaderboardContent: FC = () => {
  const {
    publicService: { profile: publicProfileService },
  } = useSupabase();

  const { control, handleSubmit } = useForm<{ search: string }>({
    defaultValues: { search: "" },
  });
  const [referrals, setReferrals] = useState<ReferralAccount[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const scrollRef = useRef<HTMLDivElement>(null);

  const fetchTopReferral = useCallback(
    async (query: { search: string }, isInitialLoad = false) => {
      if (
        !publicProfileService ||
        loading ||
        (!isInitialLoad && !hasMore && !query.search)
      )
        return;

      setLoading(true);
      if (query?.search) {
        const user = await publicProfileService.getTopRefs({
          query: query.search,
          limit: 100,
          offset: 0,
        });

        setReferrals(user);
        setHasMore(true);
        setLoading(false);
        return;
      }

      const offset = isInitialLoad ? 0 : referrals.length;
      const newReferrals = await publicProfileService.getTopRefs({
        query: "",
        limit: 20,
        offset,
      });

      if (newReferrals.length === 0) {
        setHasMore(false);
      } else {
        if (isInitialLoad) {
          setReferrals([...newReferrals]);
        } else {
          setReferrals((prev) => [
            ...prev,
            ...newReferrals.filter(
              (ref) => !prev.some((c) => c.user_id === ref.user_id)
            ),
          ]);
        }
      }

      setLoading(false);
    },
    [publicProfileService, referrals, hasMore, loading]
  );

  useEffect(() => {
    fetchTopReferral({ search: "" }, true);
  }, [publicProfileService]);

  const handleScroll = useCallback(() => {
    if (!scrollRef.current || loading || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      fetchTopReferral({ search: "" });
    }
  }, [fetchTopReferral, loading, hasMore]);

  useEffect(() => {
    const ref = scrollRef.current;
    if (ref) ref.addEventListener("scroll", handleScroll);
    return () => {
      if (ref) ref.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const scrollToBottom = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, []);

  const scrollToTop = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  }, []);

  return (
    <Flex gap="8px" flexDir="column">
      <Flex justifyContent="flex-end">
        <Text
          size="lg"
          cursor="pointer"
          colorScheme="white"
          onClick={scrollToBottom}
        >
          [scroll to bottom]
        </Text>
      </Flex>
      <form
        onSubmit={handleSubmit(({ search }) =>
          fetchTopReferral({ search: search }, search === "")
        )}
      >
        <Flex alignItems="center" justifyContent="space-between" gap="8px">
          <Controller
            name="search"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <>
                <Input
                  fontSize="20px"
                  placeholder="search by username"
                  {...field}
                />
                <Button variant="outline" type="submit">
                  search
                </Button>
              </>
            )}
          />
        </Flex>
      </form>
      <Flex
        flexDir="column"
        px="16px"
        py="8px"
        borderRadius="2px"
        border="1px solid"
        borderColor="white"
        bg="#111"
        overflowX="scroll"
        maxH="600px"
        ref={scrollRef}
        scrollBehavior="smooth"
      >
        <Flex gap="48px" alignItems="center">
          <Text w="44px" size="xl" colorScheme="white">
            rank
          </Text>
          <Text w="80px" size="xl" colorScheme="white">
            referrals
          </Text>
          <Text size="xl" colorScheme="white">
            username
          </Text>
        </Flex>
        {referrals.map((referral, index) => (
          <Flex
            gap="48px"
            alignItems="center"
            key={`referral_${referral.rank}_${index}`}
          >
            <Text w="44px" minW="44px" size="xl" colorScheme="white">
              {referral.rank}
            </Text>
            <Text w="80px" minW="80px" size="xl" colorScheme="white">
              {String(referral.count_ref_code_used)}
            </Text>
            <Text
              size="xl"
              color="secondary.light.blue.2"
              _hover={{ textDecor: "underline" }}
              cursor="pointer"
              onClick={() =>
                window.open(
                  `https://solscan.io/account/${referral.address}`,
                  "_blank"
                )
              }
            >
              {referral.nickname || referral.address?.substring(0, 6)}
            </Text>
          </Flex>
        ))}
        {loading && <Text>Loading...</Text>}
      </Flex>
      <Flex justifyContent="flex-end">
        <Text
          size="lg"
          cursor="pointer"
          colorScheme="white"
          onClick={scrollToTop}
        >
          [scroll to top]
        </Text>
      </Flex>
    </Flex>
  );
};
