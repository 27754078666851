import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  UseDisclosureProps,
} from "@chakra-ui/react";
import { Text } from "@/ui/Components/Text";
import { ChangeEvent, FC, useCallback, useRef, useState } from "react";
import { useSupabase } from "@/hooks/useSupabase";
import { Controller, useForm, useWatch } from "react-hook-form";
import { CommentDtoPayload } from "@/lib/services/chat.supbase.service";
import { toast, ToastType } from "../toast";
import { useWalletConnect } from "@/hooks/useWalletConnect";

type Props = UseDisclosureProps & { topic: string; onOk(): void };

export const PostCommentModal: FC<Props> = ({ topic, onOk, ...modalProps }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [filename, setFilename] = useState("");
  const fileInputRef = useRef<HTMLInputElement>();
  const { postComment, publicService, auth } = useSupabase();
  const { connectSolana } = useWalletConnect();

  const {
    formState: { errors },
    handleSubmit,
    ...form
  } = useForm<CommentDtoPayload>({
    defaultValues: {
      message: "",
      image_url: "",
    },
  });

  const formValues = useWatch({ control: form.control });

  const handleFileChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files[0];
      if (!file) return;

      const validTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!validTypes.includes(file.type)) {
        form.setError("image_url", {
          type: "manual",
          message: "Invalid file type. Only JPEG, PNG, and GIF are allowed.",
        });

        return;
      }

      try {
        setIsLoading(true);
        const url = await publicService.utility.uploadImage(file);
        setFilename(file.name);
        form.setValue("image_url", url);
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    },
    [publicService, form]
  );

  const handleSendMessage = useCallback(
    async (payload: CommentDtoPayload) => {
      if (!payload.message && !payload.image_url) {
        return;
      }

      try {
        setIsLoading(true);
        await postComment(topic, {
          message: payload.message,
          image_url: payload.image_url,
        });

        toast({
          title: "Comment posted",
          description: "Your comment has been successfully posted",
          type: ToastType.success,
        });
        onOk();
      } catch (err) {
        toast({
          title: "Failed to post comment",
          description: "An error occurred while posting your comment",
          type: ToastType.error,
        });
        console.warn("Failed to post comment", err);
      } finally {
        setIsLoading(false);
      }
    },
    [onOk, postComment]
  );

  return (
    <Modal isOpen={modalProps.isOpen} onClose={() => modalProps.onClose()}>
      <ModalOverlay />
      <ModalContent width={{ base: "400px", lg: "560px" }} height="auto">
        <ModalBody>
          <form onSubmit={handleSubmit(handleSendMessage)}>
            <Box display="flex" flexDirection="column" gap="24px">
              <Flex alignItems="center" justifyContent="space-between">
                <Text size="2xl" color="primary.red">
                  add a comment
                </Text>
                <Text
                  size="lg"
                  cursor="pointer"
                  color="primary.yellow"
                  _hover={{ color: "secondary.yellow" }}
                  onClick={() => modalProps.onClose()}
                >{`[close]`}</Text>
              </Flex>
              <Controller
                control={form.control}
                name="message"
                rules={{
                  maxLength: {
                    value: 255,
                    message: "Message must be at most 255 characters",
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <Flex flexDir="column" gap="8px">
                    <Text size="lg" colorScheme="white">
                      comment
                    </Text>
                    <Input
                      w="full"
                      value={value}
                      placeholder="text here"
                      onChange={(e) => onChange(e.target.value)}
                    />
                    {errors.message && (
                      <Text size="sm" color="primary.red" textAlign="right">
                        {errors.message.message as unknown as string}
                      </Text>
                    )}
                  </Flex>
                )}
              />
              <Flex
                alignItems="center"
                gap="10px"
                p="8px"
                borderRadius="1px"
                border="1px solid"
                borderColor="white"
              >
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e)}
                />
                <Button
                  onClick={() => {
                    if (fileInputRef.current) {
                      fileInputRef.current.click();
                    }
                  }}
                >{`[update]`}</Button>
                <Text size="lg" color="primary.yellow" cursor="pointer">
                  {filename || "no file chosen"}
                </Text>
              </Flex>
              {errors.image_url && (
                <Text size="sm" color="primary.red" textAlign="right">
                  {errors.image_url.message as unknown as string}
                </Text>
              )}
              {!auth?.user?.id ? (
                <Button
                  w="full"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  onClick={() => {
                    connectSolana();
                    modalProps.onClose();
                  }}
                >
                  connect wallet to post
                </Button>
              ) : (
                <Button
                  w="full"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  isLoading={isLoading}
                  type="submit"
                  disabled={!formValues.message && !formValues.image_url}
                >
                  {isLoading ? "submitting" : "post reply"}
                </Button>
              )}
            </Box>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
