import { ICommentV2 } from "@/lib/services/chat.supbase.service";
import { ImagePreviewModal } from "@/ui/Components/ImagePreviewModal";
import {
  Box,
  Flex,
  Text,
  Avatar,
  Image,
  HStack,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { FC } from "react";
import { ChatReactionContent } from "./ChatReactionContent";

export const ChatContentItem: FC<{
  comment: ICommentV2;
  onReply(chatId: string): void;
}> = ({ comment, onReply }) => {
  const imagePreviewModalProps = useDisclosure();

  return (
    <Box
      key={`chat-i-${Math.random()}-${comment.id}`}
      p="8px"
      bg="#111"
      _hover={{ bg: "primary.red.hover.2" }}
      borderRadius="2px"
      border="1px solid"
      borderColor="white"
    >
      <HStack align="top">
        <Box flex="1" display="flex" flexDir="column" gap="8px">
          <Flex
            gap="8px"
            alignItems={{ base: "flex-start", lg: "center" }}
            flexDir={{ base: "column", lg: "row" }}
          >
            <Flex gap="8px" alignItems="center">
              <Avatar
                size="sm"
                h="16px"
                w="16px"
                src={comment.user_profile?.avatar_url || "/images/avatar.png"}
              />
              <Text fontSize="lg" color="secondary.light.blue">
                {comment.user_profile?.nickname || "Anonymous"}
              </Text>
            </Flex>
            <Flex gap="8px" alignItems="center">
              <Text fontSize="lg" color="white">
                {`${moment(comment.created_at).utc().format("YYYY-MM-DD HH:mm")} [UTC]`}
              </Text>
              <ChatReactionContent comment={comment} />
              <Text
                size="lg"
                variant="link"
                onClick={() => onReply(comment.id)}
              >
                {`[reply]`}
              </Text>
            </Flex>
          </Flex>
          <Flex gap="8px" alignItems="flex-start">
            {comment.image_url && (
              <Image
                src={comment.image_url}
                w={{ base: "100px", lg: "160px" }}
                h="auto"
                objectFit={{ base: "contain", lg: "cover" }}
                cursor="pointer"
                onClick={() => imagePreviewModalProps.onOpen()}
              />
            )}
            {comment?.message && <Text size="xl">{comment?.message}</Text>}
          </Flex>
        </Box>
      </HStack>
      {imagePreviewModalProps.isOpen && (
        <ImagePreviewModal
          image={comment.image_url}
          {...imagePreviewModalProps}
        />
      )}
    </Box>
  );
};
