import { HomePageContent } from "@/ui/Pages/Home/HomePageContent";
import { NextPage } from "next";

const Page: NextPage = () => {
  return (
    <>
      <HomePageContent />
    </>
  );
};

export default Page;
