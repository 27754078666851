import {
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  UseDisclosureProps,
} from "@chakra-ui/react";
import { FC } from "react";

type Props = UseDisclosureProps & { image: string };

export const ImagePreviewModal: FC<Props> = ({ image, ...modalProps }) => {
  return (
    <Modal isOpen={modalProps.isOpen} onClose={() => modalProps.onClose()}>
      <ModalOverlay />
      <ModalContent width={{ base: "340px", lg: "480px" }} height="auto">
        <ModalBody display="flex" gap="16px" flexDir="column">
          <Image src={image} w="full" h="auto" />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
