import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useDisclosure,
  chakra,
} from "@chakra-ui/react";
import { FC, useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useWalletConnect } from "@/hooks/useWalletConnect";
import CopyToClipboard from "@/ui/Components/CopyToClipboard";
import { RoutedTab } from "@/ui/Components/RoutedTab/RouteTab";
import { HomePageSpreadContent } from "./HomePageSpreadContent";
import { HomePageChatContent } from "./HomePageChatContent";
import { HomePageLeaderboardContent } from "./HomePageLeaderboardContent";
import { EditProfileModal } from "@/ui/Components/EditProfileModal";
import { useSupabase } from "@/hooks/useSupabase";
import useIsMobile from "@/hooks/useIsMobile";

const CountdownTimer: FC<{ targetDate: string }> = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState("");
  const isMobile = useIsMobile();

  useEffect(() => {
    const updateCountdown = () => {
      const now = moment().utc();
      const target = moment(targetDate).utc();
      const duration = moment.duration(target.diff(now));

      if (duration.asMilliseconds() <= 0) {
        setTimeLeft("Time's up!");
      } else {
        const formatted = `${duration.days()}d : ${duration.hours()}h : ${duration.minutes()}m `;
        setTimeLeft(
          isMobile ? formatted : formatted + `: ${duration.seconds()}s`
        );
      }
    };

    updateCountdown();
    const intervalId = setInterval(updateCountdown, 1000);

    return () => clearInterval(intervalId);
  }, [targetDate, isMobile]);

  return (
    <Text
      fontSize={{ base: "42px", lg: "80px" }}
      color="primary.red.2"
      textAlign="center"
      fontFamily="DOS"
    >
      {timeLeft}
    </Text>
  );
};

export const HomePageContent: FC = () => {
  const { connected, address, balance, connectSolana, disconnectSolana } =
    useWalletConnect();
  const {
    auth,
    user,
    publicService: { profile: profileService },
  } = useSupabase();
  const editProfileModalDisclosure = useDisclosure();

  const [rankData, setRankData] = useState<{ ref_count: number; rank: number }>(
    {
      ref_count: 0,
      rank: 0,
    }
  );

  const handleGetUserRank = useCallback(async () => {
    if (!profileService) return;
    try {
      const rank = await profileService.getUserRank(user.user_id);
      setRankData({
        ref_count: Number(rank.count_ref_code_used) ?? 0,
        rank: rank.rank,
      });
    } catch {}
  }, [profileService, user, auth, connected]);

  useEffect(() => {
    handleGetUserRank();
  }, [profileService, user, auth, connected]);

  return (
    <Box w="full">
      <Flex
        flexDirection="column"
        alignItems="center"
        gap="32px"
        justifyContent="space-between"
        py={{ base: "60px", lg: "100px" }}
        mx="auto"
        px={{ base: "16px", lg: "24px", xl: "40px" }}
        w={{ base: "full", xl: "1066px" }}
      >
        <Flex flexDir="column" w="full" position="relative" gap="32px">
          <Flex position="relative" maxW="400px" mx="auto">
            <Image
              src="/images/bomb1.png"
              position="absolute"
              w="261px"
              h="260px"
              top={{ base: "100px", lg: "unset" }}
              left={{ base: "140px", lg: "-300px" }}
            />
            <Image
              src="/images/unpump-badge.png"
              w={{ base: "244px", lg: "400px" }}
              h="auto"
            />
          </Flex>
          <CountdownTimer targetDate="2024-12-22T21:00:00" />
          <Flex alignItems="center" justifyContent="center" gap="16px">
            <Text size="xl" colorScheme="white">
              follow us on
            </Text>
            <Image
              src="/images/twitter.png"
              alt="twitter"
              w="44px"
              h="44px"
              cursor="pointer"
              onClick={() => window.open("https://x.com/unpumpdotfun")}
            />
            <Image
              src="/images/telegram.png"
              alt="telegram"
              w="44px"
              h="44px"
              cursor="pointer"
              onClick={() => window.open("https://t.me/unpumpdotfun")}
            />
          </Flex>
          <Image
            src="/images/bomb2.png"
            position="absolute"
            w="510px"
            h="510px"
            right="-131px"
            bottom="100px"
            display={{ base: "none", lg: "block" }}
          />
          <Box position="relative" mt={{ base: "60px", lg: "100px" }} mb="32px">
            <Box
              w="full"
              h="full"
              borderRadius="1px"
              bg="primary.red"
              position="absolute"
              top="5px"
              left="5px"
              zIndex={1}
            />
            <Flex
              p="32px"
              gap="16px"
              bg="black"
              zIndex={2}
              position="relative"
              flexDirection="column"
              border="1px solid"
              borderRadius="1px"
              borderColor="secondary.gray"
            >
              {!address || !auth?.user?.id ? (
                <>
                  <Text
                    fontSize="24px"
                    textAlign="center"
                    textShadow="1px 1px 5px #FCE94F"
                  >
                    Join the ultimate dumping ground early, invite your frens,
                    and chat with fellow unpumpers to unlock exclusive rewards!
                  </Text>
                  <Box position="relative" w="full">
                    <Button
                      w="full"
                      onClick={connectSolana}
                      fontSize="24px"
                      zIndex={2}
                    >
                      CONNECT WALLET
                    </Button>
                    <Box
                      position="absolute"
                      w="full"
                      h="full"
                      borderRadius="2px"
                      bg="white"
                      top="3px"
                      left="3px"
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    gap="8px"
                    flexDir={{ base: "column", lg: "row" }}
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      gap="8px"
                      w="full"
                    >
                      <Image
                        src={user?.avatar_url || "/images/avatar.png"}
                        h="20px"
                        w="20px"
                        borderRadius="50%"
                      />
                      <Text
                        fontSize="28px"
                        color="white"
                        wordBreak="break-all"
                        textAlign="center"
                      >{`@${user?.nickname || address.substring(0, 5)}`}</Text>
                      <Text
                        fontSize="28px"
                        color="white"
                        display={{ base: "none", lg: "block" }}
                      >{`(${balance} SOL)`}</Text>
                    </Flex>
                    <Text
                      fontSize="28px"
                      color="white"
                      display={{ base: "block", lg: "none" }}
                    >{`(${balance} SOL)`}</Text>
                  </Flex>
                  <Flex alignItems="center" justifyContent="center" gap="8px">
                    <Text
                      color="white"
                      size="2xl"
                      textAlign="center"
                      wordBreak="break-all"
                      display={{ base: "none", lg: "block" }}
                    >
                      {address}
                    </Text>
                    <Text
                      color="white"
                      size="2xl"
                      textAlign="center"
                      wordBreak="break-all"
                      display={{ base: "block", lg: "none" }}
                    >
                      {address.substring(0, 6)}...
                      {address.substring(address.length - 6, address.length)}
                    </Text>
                    <CopyToClipboard text={address} />
                  </Flex>
                  <Flex
                    mx="auto"
                    flexDir="column"
                    gap="4px"
                    px="16px"
                    py="4px"
                    alignItems="center"
                    justifyContent="space-between"
                    borderRadius="2px"
                    border="1px solid"
                    borderColor="primary.yellow"
                  >
                    <Flex alignItems="center" justifyContent="center" gap="8px">
                      <Text size="lg">total referrals: </Text>
                      <Text size="lg" color="primary.yellow">
                        {rankData.ref_count}
                      </Text>
                    </Flex>
                    <Flex alignItems="center" justifyContent="center" gap="8px">
                      <Text size="lg">
                        your{" "}
                        <chakra.span color="primary.yellow">rank</chakra.span>:{" "}
                      </Text>
                      <Text size="lg" color="primary.yellow">
                        {rankData.rank ? rankData.rank : "__"}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex alignItems="center" justifyContent="center" gap="8px">
                    {/* <Text
                    color="primary.yellow"
                    size="xl"
                    cursor="pointer"
                  >{`[view]`}</Text> */}
                    <Text
                      size="xl"
                      cursor="pointer"
                      color="primary.yellow"
                      onClick={() => editProfileModalDisclosure.onOpen()}
                    >{`[edit]`}</Text>
                    <Text
                      color="primary.red.hover"
                      size="xl"
                      cursor="pointer"
                      onClick={() => disconnectSolana()}
                    >{`[disconnect]`}</Text>
                  </Flex>
                </>
              )}
            </Flex>
          </Box>
        </Flex>
        <Flex flexDir="column" w="full" position="relative">
          <RoutedTab
            containerProps={{ zIndex: 2 }}
            tabs={[
              {
                id: "speads",
                title: "spreads",
                children: () => <HomePageSpreadContent />,
              },
              {
                id: "chat",
                title: "chat",
                children: () => <HomePageChatContent />,
              },
              {
                id: "leaderboard",
                title: "leaderboard",
                children: () => <HomePageLeaderboardContent />,
              },
            ]}
          />
          <Image
            src="/images/bombmidd.png"
            position="absolute"
            w="434px"
            h="434px"
            right={{ base: "270px", lg: "-131px" }}
            bottom={{ base: "240px", lg: "-40px" }}
          />
        </Flex>
      </Flex>
      {editProfileModalDisclosure.isOpen && (
        <EditProfileModal {...editProfileModalDisclosure} />
      )}
    </Box>
  );
};
