import { Flex, FlexProps, Text } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { FC, ReactNode, useEffect, useMemo, useState } from "react";

type TabItem = {
  id: string;
  title: string;
  children: ReactNode | ((props: { changeTab(id: string): void }) => ReactNode);
};

type Props = {
  containerProps?: FlexProps;
  tabs: TabItem[];
};

export const RoutedTab: FC<Props> = ({ tabs, containerProps }) => {
  const router = useRouter();
  const [tabId, setTabId] = useState(tabs[0].id);

  const content = useMemo(
    () => tabs.find((tab) => tab.id === tabId)?.children || tabs[0].children,
    [tabId]
  );

  useEffect(() => {
    if (router.query.tab) {
      const found = tabs.find((t) => t.title === (router.query.tab as string));
      if (found) {
        setTabId(found.id);
      } else {
        setTabId(tabs[0].id);
      }
    }
  }, [router]);

  return (
    <Flex flexDir="column" gap="16px" w="full" {...containerProps}>
      <Flex alignItems="center" gap="8px">
        {tabs.map((tab) => (
          <Flex
            key={tab.id}
            cursor="pointer"
            px="8px"
            py="12px"
            borderBottom={tabId === tab.id ? "1px solid" : "none"}
            borderColor="primary.red"
            onClick={() => {
              setTabId(tab.id);
              router.push({ query: { tab: tab.id } }, null, { shallow: true });
            }}
          >
            <Text
              size="xl"
              color={tabId === tab.id ? "primary.red" : "white"}
              _hover={{ color: "primary.red" }}
            >
              {tab.title}
            </Text>
          </Flex>
        ))}
      </Flex>
      {typeof content === "function"
        ? content({ changeTab: setTabId })
        : content}
    </Flex>
  );
};
