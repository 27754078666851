import { useSupabase } from "@/hooks/useSupabase";
import { ICommentV2, IMyReaction } from "@/lib/services/chat.supbase.service";
import { Flex, Text, Image } from "@chakra-ui/react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { FC, useCallback, useEffect, useState } from "react";

export const ChatReactionContent: FC<{ comment: ICommentV2 }> = ({
  comment,
}) => {
  const [hoveredLike, setHoveredLike] = useState(false);
  const [hoveredDislike, setHoveredDislike] = useState(false);

  const {
    user,
    auth,
    authService: { chat: chatService },
    publicService: { chat: publicChatService },
  } = useSupabase();

  const { setVisible } = useWalletModal();

  const [likeCount, setLikeCount] = useState(0);
  const [dislikeCount, setDislikeCount] = useState(0);
  const [myReaction, setMyReaction] = useState<IMyReaction>({
    chat_id: "",
    user_id: "",
    reaction: 0,
  });

  const handleFetchReactions = useCallback(async () => {
    if (!chatService) return;
    try {
      const reactions = await chatService.getReactions(comment.id);
      const myReactions = reactions.reactions?.filter(
        (reaction) => reaction.user_id === user?.user_id
      );

      if (myReactions.length) {
        setMyReaction((prev) => ({
          ...prev,
          reaction: myReactions[0].reaction,
        }));
      } else {
        setMyReaction((prev) => ({
          ...prev,
          reaction: 0,
        }));
      }

      const likeCount = reactions.reactions?.filter(
        (reaction) => reaction.reaction === 1
      ).length;

      const dislikeCount = reactions.reactions?.filter(
        (reaction) => reaction.reaction === 2
      ).length;

      setLikeCount(likeCount || 0);
      setDislikeCount(dislikeCount || 0);
    } catch {}
  }, [chatService]);

  useEffect(() => {
    if (!publicChatService) return;
    setMyReaction(
      comment.my_reaction?.[0]?.user_id === user.user_id
        ? {
            chat_id: comment.id,
            user_id: user?.user_id,
            reaction: comment.my_reaction[0].reaction,
          }
        : {
            chat_id: comment.id,
            user_id: user?.user_id,
            reaction: 0,
          }
    );

    setLikeCount(comment.like?.[0]?.count || 0);
    setDislikeCount(comment.dislike?.[0]?.count || 0);
  }, [user]);

  return (
    <>
      <Flex
        gap="4px"
        alignItems="center"
        cursor="pointer"
        onMouseMove={() => setHoveredLike(true)}
        onMouseLeave={() => setHoveredLike(false)}
        onClick={() => {
          if (!auth?.user?.id) {
            setVisible(true);
            return;
          }

          chatService
            .reactComment(comment.id, 1)
            .then(() => {
              handleFetchReactions();
            })
            .catch((error) => {
              console.warn("Error reacting to comment", error);
            });
        }}
      >
        <Image
          src={
            myReaction?.reaction === 1
              ? "/icons/love-red.svg"
              : hoveredLike
                ? "/icons/love.svg"
                : "/icons/love-white.svg"
          }
          w="13px"
          h="auto"
        />
        <Text size="lg" color={hoveredLike ? "primary.red" : "white"}>
          {likeCount || 0}
        </Text>
      </Flex>
      <Flex
        gap="4px"
        alignItems="center"
        cursor="pointer"
        onMouseMove={() => setHoveredDislike(true)}
        onMouseLeave={() => setHoveredDislike(false)}
        onClick={() => {
          if (!auth?.user?.id) {
            setVisible(true);
            return;
          }

          chatService &&
            chatService
              .reactComment(comment.id, 2)
              .then(() => {
                handleFetchReactions();
              })
              .catch((error) => {
                console.warn("Error reacting to comment", error);
              });
        }}
      >
        <Image
          src={
            myReaction?.reaction === 2
              ? "/icons/bomb-red.svg"
              : hoveredDislike
                ? "/icons/bomb-hover.svg"
                : "/icons/bomb.svg"
          }
          w="13px"
          h="auto"
        />
        <Text size="lg" color={hoveredDislike ? "primary.red" : "white"}>
          {dislikeCount || 0}
        </Text>
      </Flex>
    </>
  );
};
