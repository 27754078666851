import type { ChakraProps } from "@chakra-ui/react";
import {
  IconButton,
  Skeleton,
  Tooltip,
  useClipboard,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

export type Props = {
  text: string | null;
  isLoading?: boolean;
  size?: "sm";
  onClick?: (event: React.MouseEvent) => void;
} & Partial<ChakraProps>;

const CopyToClipboard = ({
  text,
  isLoading,
  color,
  size,
  onClick,
  ...props
}: Props) => {
  const { hasCopied, onCopy } = useClipboard(text || "", 1000);
  const [copied, setCopied] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (hasCopied) {
      setCopied(true);
    } else {
      setCopied(false);
    }
  }, [hasCopied]);

  const boxSize = (size === "sm" && 3) || 5;

  if (isLoading) {
    return (
      <Skeleton
        boxSize={boxSize}
        borderRadius="sm"
        flexShrink={0}
        ml={2}
        {...props}
      />
    );
  }

  return (
    <Tooltip
      label={copied ? "Copied" : "Copy to clipboard"}
      isOpen={isOpen || copied}
    >
      <IconButton
        aria-label="copy"
        icon={(() => (
          <Image
            src="/icons/copy.svg"
            boxSize={boxSize}
            w={boxSize ?? "20px"}
            h={boxSize ?? "20px"}
          />
        ))()}
        boxSize={boxSize}
        color="gray.400"
        variant="simple"
        display="inline-block"
        flexShrink={0}
        onClick={(e) => {
          onCopy();
          onClick?.(e);
        }}
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        borderRadius={0}
        {...props}
      />
    </Tooltip>
  );
};

export default React.memo(CopyToClipboard);
