import { useSupabase } from "@/hooks/useSupabase";
import {
  PopoverModal,
  PopoverModalContent,
  PopoverModalTrigger,
} from "@/ui/Components/PopoverModal";
import { TextTooltip } from "@/ui/Components/TextTooltip";
import { toast, ToastType } from "@/ui/toast";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  GridItemProps,
  Image,
  Input,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";

const GridCard: FC<{ children: ReactNode } & GridItemProps> = ({
  children,
  ...props
}) => (
  <GridItem
    colSpan={1}
    display="flex"
    flexDir="column"
    p="16px"
    gap="12px"
    borderRadius="2px"
    border="1px solid"
    borderColor="white"
    height={{ base: "auto", lg: "340px" }}
    bg="black"
    {...props}
  >
    {children}
  </GridItem>
);

const ShareButton: FC<{
  name: string;
  link: string;
  icon: string;
  shareUrl: string;
}> = ({ name, link, icon, shareUrl }) => {
  const event = useDisclosure();
  return (
    <Flex
      onMouseMove={() => event.onOpen()}
      onMouseLeave={() => event.onClose()}
      key={`${name}-${Math.random()}`}
      py="12px"
      px="16px"
      gap="10px"
      borderRadius="4px"
      border="1px solid"
      borderColor={event.isOpen ? "primary.red.hover" : "primary.red"}
      alignItems="center"
      w="full"
      cursor="pointer"
      bg="black"
      onClick={() => {
        window.open(
          `${shareUrl}${name !== "Facebook" ? "dump your bags on unpump to earn rewards! " : ""}${link}`,
          "_blank"
        );
      }}
    >
      <Image src={icon} w="20px" h="20px" />
      <Text
        size="lg"
        color={event.isOpen ? "primary.red.hover" : "primary.red"}
      >
        share on {name}
      </Text>
    </Flex>
  );
};

const ShareRefLinkButton: FC<{ refLink: string; isDisabled?: boolean }> = ({
  refLink,
  isDisabled,
}) => {
  const modalDisclosure = useDisclosure();

  const socials = [
    {
      name: "X",
      icons: "/icons/socials_link/twitter.svg",
      shareUrl: "https://twitter.com/intent/tweet?url=",
    },
    {
      name: "Telegram",
      icons: "/icons/socials_link/telegram.svg",
      shareUrl: "https://t.me/share/url?url=",
    },
    {
      name: "Reddit",
      icons: "/icons/socials_link/reddit.svg",
      shareUrl: "https://www.reddit.com/submit?url=",
    },
    {
      name: "Facebook",
      icons: "/icons/socials_link/facebook.svg",
      shareUrl: "https://www.facebook.com/sharer/sharer.php?u=",
    },
    // {
    //   name: "Discord",
    //   icons: "/icons/socials_link/discord.svg",
    //   shareUrl: "",
    // },
    // {
    //   name: "Instagram",
    //   icons: "/icons/socials_link/instagram.svg",
    //   shareUrl: "",
    // },
    // {
    //   name: "Tiktok",
    //   icons: "/icons/socials_link/tiktok.svg",
    //   shareUrl: "",
    // },
  ];

  return (
    <Box position="relative">
      <PopoverModal
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        order={3}
        position={{ base: "unset" }}
        w={{ base: "unset", xl: "unset", lg: "unset" }}
        top={{ base: "unset", lg: "unset" }}
        bottom={{ base: "unset", lg: "unset" }}
        boxShadow="unset"
      >
        <PopoverModalTrigger>
          <Button
            variant="outline"
            onClick={() => modalDisclosure.onOpen()}
            isDisabled={isDisabled}
            bg="black"
            borderColor={
              modalDisclosure.isOpen ? "primary.red.hover" : "primary.red"
            }
          >
            share
          </Button>
        </PopoverModalTrigger>
        <PopoverModalContent
          p="24px"
          right={0}
          width="300px"
          overflow="hidden"
          display="flex"
          flexDir="column"
          gap="8px"
          border="1px solid"
          borderColor="primary.red"
          borderRadius="8px"
        >
          {socials.map((social) => (
            <ShareButton
              key={social.name}
              name={social.name}
              link={refLink}
              icon={social.icons}
              shareUrl={social.shareUrl}
            />
          ))}
        </PopoverModalContent>
      </PopoverModal>
    </Box>
  );
};

export const HomePageSpreadContent: FC = () => {
  const {
    auth,
    authService: { profile: profileService },
  } = useSupabase();
  const [nukeCodeUpdating, setNukeCodeUpdating] = useState(false);

  const {
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ refcode: string; refLink: string }>({
    defaultValues: {
      refcode: "",
      refLink: "",
    },
  });

  const form = useWatch({ control });

  const fetchNukeCode = useCallback(() => {
    profileService.getRefCode().then((refCode) => {
      if (refCode) {
        setValue("refcode", refCode.code);
        setValue("refLink", `https://unpump.fun/invite?ref=${refCode.code}`);
      }
    });
  }, [profileService]);

  const handleUpdateNukeCode = useCallback(
    async ({ refcode }: { refcode: string; refLink: string }) => {
      if (!profileService) return;
      try {
        setNukeCodeUpdating(true);
        const response = await profileService.updateRefCode(
          refcode.toLowerCase()
        );
        if (response.error) {
          console.warn("Failed to update nuke code", response.error);
          toast({
            title: "Failed to update nuke code",
            description: "An error occurred while updating your nuke code",
            type: ToastType.error,
          });

          return;
        }

        toast({
          title: "Nuke code was updated",
          description: "Your nuke code has been successfully updated",
          type: ToastType.success,
        });
        await fetchNukeCode();
      } catch (error) {
      } finally {
        setNukeCodeUpdating(false);
      }
    },
    [profileService]
  );

  useEffect(() => {
    if (!profileService) return;
    fetchNukeCode();
  }, [profileService]);

  useEffect(() => {
    if (!auth) {
      setValue("refcode", "");
      setValue("refLink", "");
    }
  }, [auth]);

  return (
    <Flex flexDir="column" gap="16px">
      <Flex
        w="full"
        px="20px"
        py="24px"
        border="1px solid"
        borderColor="white"
        borderRadius="2px"
        gap="24px"
        flexDir={{ base: "column", lg: "row" }}
        bg="black"
      >
        <Flex flexDir="column" gap="12px">
          <Text color="primary.red" size="2xl">
            my nuke code
          </Text>
          <form onSubmit={handleSubmit(handleUpdateNukeCode)}>
            <Controller
              control={control}
              name="refcode"
              rules={{
                required: "nuke code is required",
                minLength: {
                  value: 3,
                  message: "nuke code must be at least 3 characters",
                },
                maxLength: {
                  value: 20,
                  message: "nuke code must be at most 20 characters",
                },
              }}
              render={({ field: { value, onChange } }) => (
                <Flex gap="12px">
                  <Flex flexDir="column" w={{ base: "full", lg: "267px" }}>
                    <Input
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      placeholder="enter your nuke code"
                      isDisabled={!auth?.user?.id}
                      w="full"
                    />
                    {errors.refcode && (
                      <Text color="primary.red" size="sm">
                        {errors.refcode.message}
                      </Text>
                    )}
                  </Flex>
                  <Button
                    type="submit"
                    variant="outline"
                    isLoading={nukeCodeUpdating}
                    isDisabled={!value}
                  >
                    update
                  </Button>
                </Flex>
              )}
            />
          </form>
        </Flex>
        <Flex flexDir="column" gap="12px">
          <Text color="primary.red" size="2xl">
            my referral link
          </Text>
          <Flex gap="12px">
            <Input
              placeholder="enter your referral link"
              disabled={true}
              value={form.refLink}
              defaultValue={form.refLink}
              w={{ base: "full", lg: "400px" }}
              isDisabled={!auth?.user?.id}
              fontSize="20px"
            />
            <Button
              variant="outline"
              onClick={() => navigator.clipboard.writeText(form.refLink)}
              isDisabled={!form.refLink}
            >
              copy
            </Button>
            <ShareRefLinkButton
              refLink={form.refLink}
              isDisabled={!form.refLink}
            />
          </Flex>
        </Flex>
      </Flex>
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(3, 1fr)" }}
        columnGap="24px"
        rowGap="24px"
      >
        <GridCard alignItems="center">
          <Image src="/images/conquer.png" w="146px" h="100px" mx="auto" />
          <Text textAlign="center" size="2xl" color="primary.red">
            1. INVITE & CONQUER
          </Text>
          <Text textAlign="center" size="xl" color="white">
            Invite your frens to join unpump.fun by connecting their wallets
            using your referral link.
          </Text>
        </GridCard>
        <GridCard alignItems="center">
          <Image src="/images/bottle.png" w="91.546px" h="100px" mx="auto" />
          <Text textAlign="center" size="2xl" color="primary.red">
            2. SPREAD THE UNPUMP SPIRIT
          </Text>
          <Text textAlign="center" size="xl" color="white">
            These new sign-ups will be associated with you from now on.
          </Text>
        </GridCard>
        <GridCard alignItems="center">
          <Image src="/images/dollar.png" w="115.311px" h="100px" mx="auto" />
          <TextTooltip
            textAlign="center"
            size="2xl"
            color="primary.red"
            tooltipDesc="Nuke Damage and Nuke Credits measure your impact and accrued rewards. Keep stacking them—they will unlock powerful opportunities in the next phases of unpump.fun."
            text="3. RAKE IN THE REWARDS"
          />
          <Text textAlign="center" size="xl" color="white">
            Automatically earn Nuke Damage (Trading) Fees, Nuke Credits (Locked
            UN) and attractive Bonuses whenever your friends dump or participate
            in Survival Showdown.
          </Text>
        </GridCard>
      </Grid>
    </Flex>
  );
};
